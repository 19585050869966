interface FeelingStrategiesTreemapData {
  kind: 'natural' | 'intentional';
  children: {
    name: string;
    kind: 'constructive' | 'destructive' | 'ambiguous';
  }[];
}

const sadnessData: FeelingStrategiesTreemapData[] = [
  {
    kind: 'natural',
    children: [
      { name: 'Buscar conforto', kind: 'constructive' },
      { name: 'Protestar', kind: 'ambiguous' },
      { name: 'Ruminar', kind: 'destructive' },
      { name: 'Lamentar', kind: 'ambiguous' },
      { name: 'Sentir vergonha', kind: 'destructive' },
      { name: 'Retirar-se', kind: 'destructive' },
    ],
  },
  {
    kind: 'intentional',
    children: [
      { name: 'Distrair-se', kind: 'constructive' },
      { name: 'Mindfulness', kind: 'constructive' },
      { name: 'Mudar perspectiva', kind: 'constructive' },
    ],
  },
];

const angerData: FeelingStrategiesTreemapData[] = [
  {
    kind: 'natural',
    children: [
      { name: 'Gritar', kind: 'destructive' },
      { name: 'Insultar', kind: 'destructive' },
      { name: 'Discutir', kind: 'destructive' },
      { name: 'Suprimir', kind: 'destructive' },
      { name: 'Enfraquecer', kind: 'destructive' },
      { name: 'Estourar', kind: 'destructive' },
      { name: 'Repreender', kind: 'destructive' },
      { name: 'Usar força física', kind: 'destructive' },
      { name: 'Ser passivo-agressivo', kind: 'destructive' },
    ],
  },
  {
    kind: 'intentional',
    children: [
      { name: 'Retire-se e faça uma pausa', kind: 'constructive' },
      { name: 'Definir limites e ser firme', kind: 'constructive' },
      { name: 'Respirar profundamente', kind: 'constructive' },
      { name: 'Mudar perspectiva', kind: 'constructive' },
      { name: 'Distrair-se', kind: 'constructive' },
      { name: 'Evitar ou remover estímulos', kind: 'constructive' },
    ],
  },
];

const anxietyData: FeelingStrategiesTreemapData[] = [
  {
    kind: 'natural',
    children: [
      { name: 'Hesitar', kind: 'ambiguous' },
      { name: 'Congelar', kind: 'ambiguous' },
      { name: 'Retirar-se', kind: 'destructive' },
      { name: 'Ruminar', kind: 'destructive' },
      { name: 'Preocupar-se', kind: 'destructive' },
    ],
  },
  {
    kind: 'intentional',
    children: [
      { name: 'Mudar perspectiva', kind: 'constructive' },
      { name: 'Respirar profundamente', kind: 'constructive' },
      { name: 'Mindfulness', kind: 'constructive' },
      { name: 'Distrair-se', kind: 'constructive' },
    ],
  },
];

////////////////////////////////////////////////////////////////////////////////////////////////////

export const constructiveColor = '#00CECB';
export const desctructiveColor = '#FF5E5B';
export const ambiguousColor = '#FFFFEA';
export const naturalColor = '#D8D8D8';
export const intentionalColor = '#FFF8BF';

const mapToTreemapData = (props: FeelingStrategiesTreemapData[]) =>
  props.map((root) => ({
    name: root.kind === 'natural' ? 'Respostas naturais' : 'Respostas intencionais',
    color: root.kind === 'natural' ? naturalColor : intentionalColor,
    children: root.children.map((node) => ({
      name: node.name,
      color: node.kind === 'constructive' ? constructiveColor : node.kind === 'destructive' ? desctructiveColor : ambiguousColor,
      size: 1,
    })),
  }));

////////////////////////////////////////////////////////////////////////////////////////////////////

export const getTreemapData = (
  kind: 'sad' | 'anxiety' | 'anger'
): {
  name: string;
  color: string;
  children: {
    name: string;
    color: string;
    size: number;
  }[];
}[] => {
  switch (kind) {
    case 'sad':
      return mapToTreemapData(sadnessData);
    case 'anxiety':
      return mapToTreemapData(anxietyData);
    case 'anger':
      return mapToTreemapData(angerData);
  }
};
