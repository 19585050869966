import { ResponsiveContainer, Treemap } from '@component/charts/recharts-wrapper';
import { diactriclessKebabCase } from '@global/utils/string/case';
import { BodySmall, FontSize, GrayColor, HeaderHeight, Separator } from '@web/atomic';
import { isDesktopScreenSize } from '@web/utils/browser-detection';
import { scrollToId } from '@web/utils/scroll';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { headerId } from '../org.menu/client-header.utils';
import {
  ambiguousColor,
  constructiveColor,
  desctructiveColor,
  getTreemapData,
  intentionalColor,
  naturalColor,
} from './feeling-strategies.utils';

interface IFeelingStrategiesProps {
  kind: 'sad' | 'anxiety' | 'anger';
}


const FeelingStrategies: React.FunctionComponent<IFeelingStrategiesProps> = (props) => {
  const height = props.kind === 'anger' ? 600 : props.kind === 'sad' ? 370 : 550;
  return (
    <>
      <BodySmall>
        <ColorLegendBoxStyled color={naturalColor} /> Respostas naturais; <ColorLegendBoxStyled color={intentionalColor} /> Respostas
        intencionais; <ColorLegendBoxStyled color={constructiveColor} /> Ação construtiva;{' '}
        {props.kind !== 'anger' && (
          <>
            <ColorLegendBoxStyled color={ambiguousColor} /> Ação ambígua;
          </>
        )}{' '}
        <ColorLegendBoxStyled color={desctructiveColor} /> Ação destrutiva
      </BodySmall>
      <Separator />

      <ResponsiveContainer height={height}>
        <Treemap
          animationDuration={800}
          data={getTreemapData(props.kind)}
          dataKey="size"
          stroke="#fff"
          fill="#8884d8"
          content={<CustomizedContent />}
        />
      </ResponsiveContainer>
    </>
  );
};

export default FeelingStrategies;

////////////////////////////////////////////////////////////////////////////////////////////////////

class CustomizedContent extends PureComponent<any> {
  render() {
    const { depth, x, y, width, height, color, name } = this.props;

    const words = wrapLabel(name, width - 30);
    return (
      <g
        onClick={() => {
          scrollToId(
            diactriclessKebabCase(name),
            document.getElementById(headerId) ? (isDesktopScreenSize() ? HeaderHeight.Desk : HeaderHeight.Mobile) : 0
          );
        }}
      >
        <rect
          x={depth === 1 ? x : x + 8}
          y={depth === 1 ? y : y + 8}
          width={depth === 1 ? width : width - 16}
          height={depth === 1 ? height : height - 16}
          rx={depth === 1 ? 0 : 8}
          ry={depth === 1 ? 0 : 8}
          style={{
            fill: color,
            stroke: '#fff',
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />
        {depth === 1
          ? null
          : words.map((word, wIndex) => (
            <text
              key={wIndex}
              width={width - 16}
              x={x + width / 2}
              y={y + height / 2 + 14 + (wIndex - words.length / 2) * 17}
              textAnchor="middle"
              stroke="#333"
              fontSize={14}
            >
              {word}
            </text>
          ))}
      </g>
    );
  }
}

// https://medium.com/@CarysMills/wrapping-svg-text-without-svg-2-ecbfb58f7ba4
function breakString(word, maxWidth, hyphenCharacter = '-') {
  const characters = word.split('');
  const lines = [];
  let currentLine = '';
  characters.forEach((character, index) => {
    const nextLine = `${currentLine}${character}`;
    const lineWidth = getTextWidth(nextLine);
    if (lineWidth >= maxWidth) {
      const currentCharacter = index + 1;
      const isLastLine = characters.length === currentCharacter;
      const hyphenatedNextLine = `${nextLine}${hyphenCharacter}`;
      lines.push(isLastLine ? nextLine : hyphenatedNextLine);
      currentLine = '';
    } else {
      currentLine = nextLine;
    }
  });
  return { hyphenatedStrings: lines, remainingWord: currentLine };
}
function getTextWidth(text, font = '400 14px Montserrat,sans-serif') {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return context.measureText(text).width;
}
function wrapLabel(label, maxWidth) {
  if (!label) return [];
  const words = label.split(' ');
  const completedLines = [];
  let nextLine = '';
  words.forEach((word, index) => {
    const wordLength = getTextWidth(`${word} `);
    const nextLineLength = getTextWidth(nextLine);
    if (wordLength > maxWidth) {
      const { hyphenatedStrings, remainingWord } = breakString(word, maxWidth);
      completedLines.push(nextLine, ...hyphenatedStrings);
      nextLine = remainingWord;
    } else if (nextLineLength + wordLength >= maxWidth) {
      completedLines.push(nextLine);
      nextLine = word;
    } else {
      nextLine = [nextLine, word].filter(Boolean).join(' ');
    }
    const currentWord = index + 1;
    const isLastWord = currentWord === words.length;
    if (isLastWord) {
      completedLines.push(nextLine);
    }
  });
  return completedLines.filter((line) => line !== '');
}

////////////////////////////////////////////////////////////////////////////////////////////////////

const ColorLegendBoxStyled = styled.span`
  display: inline-block;
  height: ${FontSize.Small};
  width: 24px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  border: 1px solid ${GrayColor.Gray};
  margin-bottom: -1px;
`;
